<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">My meetings</a>
          </li>
          <li class="breadcrumb-item active">
            Invites
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'meetings'}"
            >
              <i
                class="fas fa-arrow-circle-left"
              />
            </router-link>
            <div class="section-header-title-group">
              <h1>Meeting {{ updated ? 'updated' : 'saved' }}!</h1>
            </div>
          </div>
        </div>
        <div class="row row-has-panel">
          <div :class="isShowGotcha ? 'col-xl-9' : 'col-xl-12'">
            <div class="section-panel">
              <div class="section-panel-body">
                <div class="row">
                  <div class="col-12">
                    <h3 class="mb-1">
                      Invite participants now:
                    </h3>

                    <b>Direct URL:</b> <a
                      :href="url"
                      target="_blank"
                    >{{ url }}</a>

                    <br>
                    <b>Subject:</b> {{ selMeeting.subject }}
                    <br>
                    <b>Time:</b>
                    <span v-if="selMeeting.starts_at">
                      {{ $moment(selMeeting.starts_at, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm') }}
                      -
                      {{ $moment(selMeeting.ends_at, 'YYYY-MM-DD HH:mm').format('HH:mm') }}
                      (GMT{{ selMeeting.timezone_offset }})
                    </span>
                    <!--
                                        <br>
                                        <b>Timezone:</b>
                                        <span v-if="selMeeting.timezone">
                                            {{ selMeeting.timezone }} GMT{{ selMeeting.timezone_offset }}
                                        </span>
                                        -->
                    <br>
                    <b>Description:</b> {{ selMeeting.description }}

                    <br>
                    <br>
                  </div>
                </div>

                <div class="row h-inline-btn-group">
                  <div class="col-lg-6 text-center text-md-left">
                    <a
                      :href="selMeeting.calendar ? selMeeting.calendar.google : ''"
                      target="_blank"
                      class="btn btn-primary"
                    >
                      Open in Gcal
                    </a>
                    <a
                      :href="selMeeting.calendar ? selMeeting.calendar.ics : ''"
                      class="btn btn-primary ml-md-2"
                      :download="icsFilename"
                    >
                      Download ICS
                    </a>
                  </div>
                  <div class="col-lg-6 text-center text-md-right mt-1 mt-lg-0">
                    <button
                      class="btn btn-secondary ml-md-2"
                      @click="copyTextInvite"
                    >
                      Copy Text invite
                    </button>
                    <textarea
                      ref="txtInvite"
                      class="position-absolute hidden"
                      type="text"
                      :value="textInvite(selMeeting)"
                    />

                    <button
                      class="btn btn-secondary ml-md-2"
                      @click="copyUrl"
                    >
                      Copy URL
                    </button>
                    <input
                      ref="txtUrl"
                      class="position-absolute hidden"
                      type="text"
                      :value="url"
                    >
                  </div>
                </div>

                <hr>
                <div class="row">
                  <div class="col-12 text-right">
                    <router-link :to="{name: 'meetings'}">
                      <button class="btn btn-secondary">
                        Done
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-panel mt-2">
              <div class="section-panel-body">
                <plugins />
              </div>
            </div>
          </div>
          <div
            v-if="isShowGotcha"
            class="col-xl mt-2 mt-xl-0"
          >
            <gotcha
              :tip-list="tipList"
              @close="isShowGotcha = false"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Gotcha from '@/components/Gotcha.vue';
import {meetingStore} from '@/store/__STORE_meetings';
import Plugins from '@/components/Plugins.vue';
import TextInviteMixin from '@/mixins/TextInviteMixin';

export default {
  components: {
    Gotcha,
    Plugins,
  },
  mixins: [TextInviteMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tipList: [
        'Create a scheduled call here by filling out the subject, date and time',
        'Send invites to your guest via your email provider or share in plain text by copy & pasting',
      ],
      isShowGotcha: true,
    };
  },
  computed: {
    selMeeting() {
      return meetingStore.selMeeting;
    },
    updated() {
      return this.$router.currentRoute.name === 'meeting-updated';
    },
    url() {
      if (!this.selMeeting.meeting_room) {
        return '';
      }
      const a = document.createElement('a');
      a.href = this.$router.resolve({
        name: 'welcome-guest',
        params: {
          webrtcAlias: this.selMeeting.meeting_room ? this.selMeeting.meeting_room.webrtc.alias : '',
        },
      }).href;
      return a.href;
    },
    icsFilename() {
      return 'SimplyVideo Invite ' + this.selMeeting.id;
    },
  },
  created() {
    if (this.id) {
      meetingStore.getMeeting(this.id)
          .then(() => {
          })
          .catch((err) => {

          });
    }
  },
  mounted() {
  },
  methods: {
    copyUrl() {
      this.copyToClipboard(
          this.$refs.txtUrl,
      );
    },
    copyTextInvite() {
      this.copyToClipboard(
          this.$refs.txtInvite,
      );
    },
    copyToClipboard(domElement) {
      // let txtUrl = this.$refs.txtUrl;

      domElement.setAttribute('type', 'text');
      domElement.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$toast.success('Copied successfully');
        } else {
          this.$toast.error('Oops, unable to copy');
        }
      } catch (err) {
        this.$toast.error('Oops, unable to copy');
      }

      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import "@/assets/scss/__variables";

@include media-breakpoint-down(sm) {
  .h-inline-btn-group {
    a,
    button {
      margin-top: 0.5rem;
      width: 75%;
    }
  }
}

.hidden {
  left:-9999px;
  opacity: 0;
  z-index:-1;
}
</style>
